<template>
  <div>
    <v-overlay :value="isLoadingSpinner" :opacity="`0.5`" zIndex="99999"
      ><v-progress-circular
        indeterminate
        size="70"
        width="7"
        color="primary"
      ></v-progress-circular
    ></v-overlay>
    <v-snackbar v-model="snackbar" :color="snackbar_class" elevation="24">
      {{ text_snackbar }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
      v-model="showGoDialog"
      v-if="current_row !== null && current_row !== undefined"
      max-width="700px"
    >
      <v-form ref="form" lazy-validation>
        <v-card>
          <v-card-text class="objective_content">
            <div class="title_contents">
              <div class="objective_content">
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <span class="title"
                      >{{ this.$t("label") + " " + this.$t("brands.name") }}:
                    </span>
                    <span>{{ current_row.name }}</span>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <span class="title"
                      >{{
                        this.$t("brands.brand") + " " + this.$t("brands.name")
                      }}:
                    </span>
                    <span>{{ current_row.brand.name }}</span>
                  </v-col>
                </v-row>
              </div>
            </div>

            <v-row class="mt-2">
              <v-col cols="12" sm="12" md="12">
                <v-row class="mt-2 text-center">
                  <v-col cols="12" sm="12" md="12">
                    <v-btn
                      color="success"
                      dark
                      class="mb-2 ml-2"
                      :loading="isSelectingIdPOSperLabel"
                      @click="onUploadExcelIdPOSperLabel"
                    >
                      <b-icon
                        icon="file-excel-fill"
                        aria-hidden="true"
                      ></b-icon>
                      {{ buttonTextIdPOSperLabel }}
                    </v-btn>
                    <input
                      ref="uploader_idproduct_per_assortments"
                      class="d-none"
                      type="file"
                      accept=".xls,.xlsx"
                      @change="onFileChangedIdPOSperLabel"
                    />
                    <v-btn
                      color="warning"
                      dark
                      class="mb-2 ml-2"
                      @click="downloadexcelposes"
                    >
                      <b-icon
                        icon="file-excel-fill"
                        aria-hidden="true"
                      ></b-icon>
                      Export
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row class="mt-2">
                  <v-col cols="12" sm="12" md="12">
                    <v-data-table
                      :headers="columns"
                      :items="tableData"
                      :items-per-page="10"
                      :footer-props="{
                        'items-per-page-options': [10, 50, 100]
                      }"
                      :options.sync="options"
                      class="elevation-1"
                    >
                      <template
                        v-for="(col, i) in filters"
                        v-slot:[`header.${i}`]="{ header }"
                      >
                        <div
                          style="display: inline-block; padding: 16px 0;"
                          v-bind:key="i"
                        >
                          <span>{{ header.text }}</span>
                        </div>
                        <div
                          style="float: right; margin-top: 8px"
                          v-bind:key="`A` + i"
                        >
                          <v-menu
                            :close-on-content-click="false"
                            :nudge-width="200"
                            offset-y
                            transition="slide-y-transition"
                            left
                            fixed
                            style="position: absolute; right: 0"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                color="indigo"
                                icon
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon
                                  small
                                  :color="
                                    activeFilters[header.value] &&
                                    activeFilters[header.value].length <
                                      filters[header.value].length
                                      ? 'red'
                                      : 'default'
                                  "
                                >
                                  mdi-filter-variant
                                </v-icon>
                              </v-btn>
                            </template>
                            <v-list flat dense class="pa-0">
                              <v-list-item-group
                                multiple
                                v-model="activeFilters[header.value]"
                                class="py-2"
                              >
                                <div class="search_input">
                                  <v-text-field
                                    hide-details
                                    v-model="searchString[header.value]"
                                    placeholder="Search ..."
                                    class="pl-2 pr-2"
                                    @input="searchItem(header.value)"
                                  ></v-text-field>
                                </div>
                                <template
                                  v-for="(item, j) in filters[header.value]"
                                >
                                  <v-list-item
                                    v-bind:key="`A${j}`"
                                    :value="item"
                                    :ripple="false"
                                  >
                                    <template
                                      v-slot:default="{ active, toggle }"
                                    >
                                      <v-list-item-action>
                                        <v-checkbox
                                          :input-value="active"
                                          :true-value="item"
                                          @click="toggle"
                                          color="primary"
                                          :ripple="false"
                                          dense
                                        ></v-checkbox>
                                      </v-list-item-action>
                                      <v-list-item-content>
                                        <v-list-item-title
                                          v-text="item"
                                        ></v-list-item-title>
                                      </v-list-item-content>
                                    </template>
                                  </v-list-item>
                                </template>
                              </v-list-item-group>
                              <v-row no-gutters>
                                <v-col cols="6">
                                  <v-btn
                                    text
                                    block
                                    @click="toggleAll(header.value)"
                                    color="success"
                                    >{{ $t("toggleall") }}</v-btn
                                  >
                                </v-col>
                                <v-col cols="6">
                                  <v-btn
                                    text
                                    block
                                    @click="clearAll(header.value)"
                                    color="warning"
                                    >{{ $t("clearall") }}</v-btn
                                  >
                                </v-col>
                              </v-row>
                            </v-list>
                          </v-menu>
                        </div>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click.stop="showGoDialog = false"
            >
              {{ $t("cancel") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import _ from "lodash";
import download from "js-file-download";

export default {
  name: "goDialogLabel",
  props: ["value", "row", "reloadTable"],
  components: {},
  data: function() {
    return {
      current_row: null,
      labelPosArr: [],
      assortmentProArr: [],

      isProgressing: false,
      isUploadingExcelFile: false,
      selectedFile: null,
      /********** snackbar **********/
      snackbar: false,
      text_snackbar: "El archivo importado no es válido.",
      vertical: true,
      snackbar_class: "deep-purple",

      isSelectingIdPOSperLabel: false,
      selectedFileIdposperlabel: null,
      defaultbuttonTextIdPOSperLabel: "Import",

      /********** table **********/
      current_rows: null,
      tableData: [],
      tempdata: [],
      originaltableData: [],
      options: {},
      loading: false,
      filterCriteria: {},
      user_status: ["active", "inactive"],
      filters: { posId: [], name: [], status: [] },
      initFilterList: { posId: [], name: [], status: [] },
      activeFilters: {},
      searchString: { posId: "", name: "", status: "" },

      /********************************* Loading Spinner Overlay *********************************/
      isLoadingSpinner: false
    };
  },
  watch: {
    async row(newVal, oldVal) {
      this.current_row = newVal;
      console.log("this.current_row - ", this.current_row);
      if (newVal) {
        this.init(newVal);
      }
    },
    options: {
      handler() {
        this.sortChange();
      },
      deep: true
    }
  },
  computed: {
    showGoDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    buttonTextIdPOSperLabel() {
      return this.isSelectingIdPOSperLabel
        ? "Uploading..."
        : this.defaultbuttonTextIdPOSperLabel;
    },
    columns() {
      return [
        {
          text: "IdPOS",
          value: "posId",
          width: "25%",
          filter: value => {
            return this.activeFilters.posId
              ? this.activeFilters.posId.includes(value)
              : true;
          }
        },
        {
          text: "POS " + this.$t("brands.name"),
          value: "name",
          width: "50%",
          filter: value => {
            return this.activeFilters.name
              ? this.activeFilters.name.includes(value)
              : true;
          }
        },
        {
          text: this.$t("salesforce.status"),
          value: "status",
          width: "25%",
          filter: value => {
            return this.activeFilters.status
              ? this.activeFilters.status.includes(value)
              : true;
          }
        }
      ];
    }
  },
  methods: {
    initFilters() {
      let keys = Object.keys(this.initFilterList);
      for (const key of keys) {
        this.searchString[key] = "";
        this.initFilterList[key] = this.tableData
          .map(d => {
            return d[key];
          })
          .filter((value, index, self) => {
            return self.indexOf(value) === index;
          });
      }
      this.filters = Object.assign({}, this.initFilterList);
      this.activeFilters = Object.assign({}, this.filters);
    },
    searchItem(col) {
      this.changeStr(col, this.searchString[col]);
    },
    changeStr(col, search_str) {
      if (search_str) {
        if (col === "id") {
          this.filters[col] = this.initFilterList[col].filter(
            item => item === parseInt(search_str)
          );
        } else {
          this.filters[col] = this.initFilterList[col].filter(item =>
            item.toLowerCase().includes(search_str.toLowerCase())
          );
        }
      } else {
        this.filters[col] = this.initFilterList[col];
      }
      this.activeFilters = Object.assign({}, this.filters);
    },
    toggleAll(col) {
      this.activeFilters[col] = this.tableData
        .map(d => {
          return d[col];
        })
        .filter((value, index, self) => {
          return self.indexOf(value) === index;
        });
      this.searchString[col] = null;
      this.changeStr(col, null);
    },
    clearAll(col) {
      this.activeFilters[col] = [];
    },
    sortChange() {
      let { sortBy, sortDesc } = this.options;
      if (sortBy && sortBy.length > 0) {
        sortBy = sortBy[0];
        sortDesc = sortDesc[0];
        if (sortDesc) {
          this.tempdata = _.sortBy(this.tempdata, sortBy).reverse();
        } else {
          this.tempdata = _.sortBy(this.tempdata, sortBy);
        }
        this.tableData = this.tempdata;
      }
    },
    initTable(newVal) {
      this.current_rows = newVal;
      this.tableData = this.current_rows;
      this.originaltableData = this.current_rows;
      this.tempdata = this.current_rows;
      this.initFilters();
    },
    init() {
      this.labelPosArr = this.current_row.pos_tags;
      this.initTable(this.labelPosArr);
    },
    async downloadexcelposes() {
      let fetch_url = `pos/tags/downloadexcelposes/${this.current_row.id}`;
      const response = await ApiService.get(fetch_url, {
        responseType: "blob"
      });
      download(response, "labelpos.xlsx");
    },
    onUploadExcelIdPOSperLabel() {
      this.isSelectingIdPOSperLabel = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelectingIdPOSperLabel = false;
        },
        { once: false }
      );
      this.$refs.uploader_idproduct_per_assortments.click();
    },
    async onFileChangedIdPOSperLabel(e) {
      this.selectedFileIdposperlabel = e.target.files[0];
      if (
        window.confirm(
          `¿Está seguro de que desea cargar este archivo de Excel?`
        )
      ) {
        this.isSelectingIdPOSperLabel = true;
        this.isLoadingSpinner = true;
        let data = new FormData();
        data.append("file", this.selectedFileIdposperlabel);
        this.$refs.uploader_idproduct_per_assortments.value = "";
        try {
          const response = await ApiService.post(
            `pos/tags/uploadexcelIdposperlabel/` + this.current_row.id,
            data,
            {
              headers: {
                "Content-Type": `multipart/form-data;`
              }
            }
          );
          const {
            success,
            insertedRowCount,
            failedRows,
            invalidFile,
            message
          } = response;
          this.snackbar_class = "deep-purple";
          if (success && insertedRowCount > 0) {
            this.text_snackbar = `${insertedRowCount} row${
              insertedRowCount > 1 ? "s" : ""
            } imported successfully`;
            this.snackbar = true;
            await this.updatedRowData(this.current_row);
          } else if (failedRows) {
            this.text_snackbar = `Inserted count: ${response.insertedRowCount}, Failed count: ${response.failedRows.length}`;
            this.snackbar = true;
            this.exportCSVfileGlobal(response.titles, response.failedRows);
            await this.updatedRowData(this.current_row);
          } else if (!invalidFile && message) {
            this.text_snackbar = message;
            this.snackbar_class = "red";
            this.snackbar = true;
          }
          this.isSelectingIdPOSperLabel = false;
          this.isLoadingSpinner = false;
        } catch (error) {
          console.log(error);
          this.isLoadingSpinner = false;
          this.isSelectingIdPOSperLabel = false;
        }
      }
    },
    async updatedRowData(row_data) {
      let fetch_url = "pos/tags/getitem/" + row_data.id;
      try {
        const resp = await ApiService.get(fetch_url);
        console.log(resp);
        this.current_row = resp.data;
        this.init();
      } catch (error) {}
    }
  },
  async updated() {},
  async mounted() {}
};
</script>

<style>
.objective_content .row span {
  color: #000;
  font-size: 1rem;
}
.objective_content .row span.title {
  font-weight: bold;
  font-size: 1rem !important;
}
.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.obj-title {
  color: rgba(0, 0, 0, 0.6) !important;
  font-weight: 500 !important;
  font-size: 1.25rem !important;
}
.title_contents {
  border: 3px solid #727272;
  border-radius: 5px;
}
.top_title h4 {
  margin: 0;
}
.top_title {
  padding: 15px 15px 15px 15px;
  border-bottom: 3px solid #727272;
}
.objective_content {
  padding: 15px;
}
</style>
