<template>
  <div>
    <v-card elevation="2" outlined shaped tile>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="6" md="6">
            {{ $t("postags") }}
          </v-col>
          <v-col cols="12" sm="6" md="6" class="text-right">
            <v-btn color="primary" dark class="mb-2" @click="onItemEdit">
              {{ $t("newitem") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-snackbar v-model="snackbar" :color="snackbar_class" elevation="24">
        {{ text_snackbar }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>

      <goDialogLabel
        ref="goDialogLabel"
        v-model="showGoDialog"
        :reloadTable="getDataFromApi"
        :row="selectedRow"
      />

      <v-dialog v-if="selected" scrollable v-model="showEdit" max-width="500px">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text class="text--primary">
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="selected.name"
                      v-bind:label="$t('brands.name')"
                      required
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-textarea
                      v-model="selected.description"
                      v-bind:label="$t('brands.description')"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <h6>Color</h6>
                    <v-row>
                      <v-col
                        v-for="(colorItem, key) in labelColors"
                        v-bind:key="key"
                        cols="12"
                        sm="2"
                      >
                        <div
                          :style="
                            `margin: 0 auto; width: 50px; height: 50px; cursor: pointer; display: flex; align-items: flex-start; justify-content: flex-end; padding: 3px; background-color: ${colorItem}`
                          "
                          @click="selected.bgColorValue = colorItem"
                        >
                          <v-icon v-if="selected.bgColorValue === colorItem"
                            >mdi-check-circle</v-icon
                          >
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-autocomplete
                      v-model="selected.brandId"
                      v-bind:label="$t('brands.brand')"
                      v-bind:placeholder="$t('start_typing_to_search')"
                      item-text="name"
                      item-value="id"
                      :search-input.sync="search_brand"
                      :loading="isBrandSearching"
                      :items="filtered_brands"
                      hide-no-data
                      hide-selected
                      filled
                      required
                      :rules="requiredRules"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-menu
                      ref="date_menu_start"
                      v-model="date_menu_start"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="selected.startDate"
                          v-bind:label="$t('start_date')"
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          required
                          :rules="requiredRules"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="selected.startDate"
                        no-title
                        @input="date_menu_start = false"
                        :first-day-of-week="1"
                        :locale="this.$i18n.locale"
                        :reactive="reactive_start"
                        @change="onChangeDates"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-menu
                      ref="date_menu_end"
                      v-model="date_menu_end"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="selected.endDate"
                          v-bind:label="$t('end_date')"
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          required
                          :rules="requiredRules"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="selected.endDate"
                        no-title
                        @input="date_menu_end = false"
                        :first-day-of-week="1"
                        :locale="this.$i18n.locale"
                        :reactive="reactive_end"
                        @change="onChangeDates"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeEditDialog">
                {{ $t("cancel") }}
              </v-btn>
              <v-btn color="blue darken-1" text @click="onSaveClick(selected)">
                {{ $t("save") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>

      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="text-h6">{{ $t("confirmdelete") }}</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete">{{
              $t("cancel")
            }}</v-btn>
            <v-btn color="blue darken-1" text @click="deleteItemConfirm"
              >OK</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <ve-table
              id="data-table"
              fixed-header
              max-height="calc(100vh - 330px)"
              :columns="columns"
              :table-data="assortments"
              :border-around="true"
              :border-x="true"
              :border-y="true"
              :scroll-width="1500"
              :sort-option="sortOption"
              :cell-style-option="cellStyleOption"
            />
            <div v-show="showEmpty" class="empty-data">Data Empty.</div>
            <div class="table-pagination mt-2 text-right">
              <ve-pagination
                :total="totalassortments"
                :page-index="pageIndex"
                :page-size="pageSize"
                :page-size-option="pageOptions"
                @on-page-number-change="pageNumberChange"
                @on-page-size-change="pageSizeChange"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import _ from "lodash";
import download from "js-file-download";
import goDialogLabel from "./goDialogLabel.vue";

export default {
  name: "Assortments",
  components: {
    goDialogLabel
  },
  data: function() {
    return {
      yn_type: ["Yes", "No"],
      status_type: [
        { label: "Active", value: "active" },
        { label: "Inactive", value: "inactive" }
      ],
      labelColors: [],
      /********** form-validation options **********/
      valid: true,
      requiredRules: [v => !!v || "Required"],
      returnsmaxRules: [
        v => !!v || "Required",
        v => (v && v >= 0) || "Este campo debe estar por encima de 0",
        v => (v && v <= 100) || "This field should not be above 100"
      ],
      selected: null,
      editedIndex: -1,
      deleteItemId: -1,
      selectedRow: null,
      showGoDialog: false,

      /********** others **********/
      isSelecting: false,
      selectedFile: null,
      defaultButtonText: "Import Excel",

      isSelectingIdposperassortments: false,
      selectedFileIdposperassortments: null,
      defaultButtonTextIdposperassortments: "Import Excel",

      /********** snackbar **********/
      snackbar: false,
      text_snackbar: "The imported file is invalid.",
      vertical: true,
      snackbar_class: "deep-purple",

      /********** create or edit dialog options **********/
      showEdit: false,
      dialogDelete: false,
      isAutoCompleteSearching: false,
      filtered_autocomplete: [],
      search_brand: null,
      isBrandSearching: false,
      filtered_brands: [],
      search_product: null,
      isProductSearching: false,
      filtered_products: [],
      search_operator: null,
      isOperatorSearching: false,
      filtered_operators: [],
      date_menu_start: false,
      date_menu_end: false,
      reactive_start: false,
      reactive_end: false,

      totalassortments: 0,
      assortments: [],
      /********** vue-easytable options **********/
      loadingInstance: null,
      sorting: null,
      sortOption: {
        sortChange: params => {
          this.sortChange(params);
        }
      },
      pageIndex: 1,
      pageSize: 100,
      pageOptions: [10, 50, 100],
      filterable_col_list: [
        "name",
        "description",
        "brand",
        "startDate",
        "endDate",
        "bgColorValue"
      ],
      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {
          if (this.filterable_col_list.includes(column.field)) {
            return "filterable-column " + column.field;
          }
        }
      },
      filterCriteria: {
        name: {},
        description: {},
        brand: {},
        startDate: {},
        endDate: {},
        bgColorValue: {}
      },
      showEmpty: false
    };
  },
  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
    async search_brand(val) {
      // let fetch_url =
      //   "assortment/get_search_list?target=brand&&filter_name=" + val;
      // const resp = await ApiService.get(fetch_url);
      // this.filtered_brands = resp.data;
      this.search_brand_list(val);
    }
  },
  computed: {
    buttonText() {
      return this.isSelecting ? "Uploading..." : this.defaultButtonText;
    },
    buttonTextIdposperassortments() {
      return this.isSelectingIdposperassortments
        ? "Uploading..."
        : this.defaultButtonTextIdposperassortments;
    },
    formTitle() {
      return this.editedIndex === -1 || this.editedIndex === undefined
        ? this.$t("newitem")
        : this.$t("edititem");
    },
    columns() {
      return [
        {
          key: "id",
          title: "ID",
          field: "id",
          align: "left",
          width: 70,
          sortBy: "ASC"
        },
        {
          key: "name",
          title: this.$t("label") + " " + this.$t("brands.name"),
          field: "name",
          align: "left",
          width: 150,
          sortBy: "",
          fixed: "left",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["name"]}
                    apiUrl={`pos/tags/getfilterlist`}
                    columnName={`name`}
                    isFullText={true}
                    filterCriteria={this.filterCriteria["name"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "name")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "description",
          title: this.$t("brands.description"),
          field: "description",
          align: "left",
          width: 200,
          sortBy: "",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["description"]}
                    apiUrl={`pos/tags/getfilterlist`}
                    columnName={`description`}
                    isFullText={false}
                    filterCriteria={this.filterCriteria["description"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "description")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "bgColorValue",
          title: this.$t("Color"),
          field: "bgColorValue",
          align: "center",
          width: 150,
          sortBy: "",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div
                style={`margin: 0 auto; width: 50px; height: 50px; background-color: ${row.bgColorValue}`}
              ></div>
            );
          }
        },
        {
          key: "brand",
          title: this.$t("brands.brand") + " " + this.$t("brands.name"),
          field: "brand",
          align: "left",
          width: 200,
          sortBy: "",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{row.brand && row.brand.name}</span>;
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["brand"]}
                    apiUrl={`pos/tags/getfilterlist`}
                    columnName={`brand`}
                    isFullText={true}
                    filterCriteria={this.filterCriteria["brand"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "brand")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },

        {
          key: "startDate",
          title: this.$t("start_date"),
          field: "startDate",
          align: "left",
          width: 150,
          sortBy: "",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <input
                    type="date"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["startDate"]["from"]}
                    value={this.filterCriteria["startDate"]["from"]}
                    placeholder="From - "
                  />
                  <br />
                  <input
                    type="date"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["startDate"]["to"]}
                    value={this.filterCriteria["startDate"]["to"]}
                    placeholder="To - "
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "startDate")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "endDate",
          title: this.$t("end_date"),
          field: "endDate",
          align: "left",
          width: 150,
          sortBy: "",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <input
                    type="date"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["endDate"]["from"]}
                    value={this.filterCriteria["endDate"]["from"]}
                    placeholder="From - "
                  />
                  <br />
                  <input
                    type="date"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["endDate"]["to"]}
                    value={this.filterCriteria["endDate"]["to"]}
                    placeholder="To - "
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "endDate")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },

        {
          key: "q",
          title: this.$t("salesforce.Edit"),
          field: "actions",
          align: "center",
          width: 100,
          fixed: "right",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div>
                <v-icon
                  medium
                  color="primary"
                  class="mr-2"
                  on-click={() => this.onItemEdit(row)}
                >
                  mdi-pencil
                </v-icon>
                <v-icon
                  medium
                  color="error"
                  on-click={() => this.onItemRemove(row.id)}
                >
                  mdi-delete
                </v-icon>
              </div>
            );
          }
        },
        {
          key: "go",
          title: this.$t("go"),
          field: "go",
          align: "center",
          width: 80,
          fixed: "right",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div>
                <v-btn
                  color="blue darken-1"
                  text
                  on-click={() => this.onItemGo(row)}
                >
                  <b-icon icon="box-arrow-up-right" aria-hidden="true"></b-icon>
                </v-btn>
              </div>
            );
          }
        },
        {
          key: "copy",
          title: this.$t("copy"),
          field: "copy",
          align: "center",
          width: 80,
          fixed: "right",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div>
                <v-btn
                  dark
                  color="success darken-1"
                  text
                  on-click={() => this.onItemCopy(row.id)}
                >
                  <b-icon icon="clipboard-plus" aria-hidden="true"></b-icon>
                </v-btn>
              </div>
            );
          }
        }
      ];
    }
  },
  methods: {
    async search_brand_list(val) {
      let fetch_url = `pos/tags/getfilterlist?column=selectablebrands&isFullText=true&filterValue=${val}`;
      const resp = await ApiService.get(fetch_url);
      this.filtered_brands = resp.data;
    },
    async onItemGo(row) {
      this.selectedRow = row;
      await this.$refs.goDialogLabel.updatedRowData(this.selectedRow);
      this.showGoDialog = true;
    },
    async onItemCopy(id) {
      if (window.confirm(`Are you sure you want to copy?`)) {
        try {
          let status = await ApiService.post(`pos/tags/copydata/${id}`);
          if (status !== null) {
            const { success, message, data } = status;
            if (success === undefined && data) {
              logInfo("éxito");
              this.getDataFromApi();
            } else if (success === false) {
              logError(message);
            }
          }
        } catch (error) {}
      }
    },
    createSelectedModel(item) {
      console.log(item);
      let model = {
        id: _.get(item, "id"),
        name: _.get(item, "name"),
        description: _.get(item, "description"),
        brandId: _.get(item, "brandId"),
        brand: _.get(item, "brand"),
        startDate: _.get(item, "startDate"),
        endDate: _.get(item, "endDate"),
        bgColorValue: _.get(item, "bgColorValue")
      };
      this.search_brand_list("");
      console.log(model);
      return model;
    },
    onItemEdit(item) {
      this.selected = this.createSelectedModel(item);
      this.editedIndex = this.selected.id;
      this.showEdit = true;
    },
    onUploadExcelClick() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: false }
      );
      this.$refs.uploader.click();
    },
    onUploadExcelIdposperassortmentClick() {
      this.isSelectingIdposperassortments = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelectingIdposperassortments = false;
        },
        { once: false }
      );
      this.$refs.uploader_idpos_per_assortments.click();
    },
    closeEditDialog() {
      this.showEdit = false;
      this.$nextTick(() => {
        this.selected = null;
        this.editedIndex = -1;
      });
    },
    async onFileChanged(e) {
      this.selectedFile = e.target.files[0];
      console.log(this.selectedFile);
      if (window.confirm(`Are you sure you want to upload this excel-file?`)) {
        this.isSelecting = true;
        let data = new FormData();
        data.append("file", this.selectedFile);
        this.$refs.uploader.value = "";
        const response = await ApiService.post(`assortment/uploadexcel`, data, {
          headers: {
            "Content-Type": `multipart/form-data;`
          }
        });
        const {
          success,
          insertedRowCount,
          failedRows,
          invalidFile,
          message
        } = response;
        this.snackbar_class = "deep-purple";
        if (success && insertedRowCount > 0) {
          this.text_snackbar = `${insertedRowCount} row${
            insertedRowCount > 1 ? "s" : ""
          } imported successfully`;
          this.snackbar = true;
        } else if (failedRows) {
          this.text_snackbar = `Inserted count: ${response.insertedRowCount}, Failed count: ${response.failedRows.length}`;
          this.snackbar = true;
          this.exportCSVfile(response.titles, response.failedRows);
        } else if (!invalidFile && message) {
          this.text_snackbar = message;
          this.snackbar_class = "red";
          this.snackbar = true;
        }
        this.isSelecting = false;
        this.getDataFromApi();
      }
    },
    async onFileChangedIdposperassortments(e) {
      this.selectedFileIdposperassortments = e.target.files[0];
      console.log(this.selectedFileIdposperassortments);
      if (window.confirm(`Are you sure you want to upload this excel-file?`)) {
        this.isSelectingIdposperassortments = true;
        let data = new FormData();
        data.append("file", this.selectedFileIdposperassortments);
        this.$refs.uploader_idpos_per_assortments.value = "";
        try {
          const response = await ApiService.post(
            `assortment/uploadexcelIdposperassortments`,
            data,
            {
              headers: {
                "Content-Type": `multipart/form-data;`
              }
            }
          );
          const {
            success,
            insertedRowCount,
            failedRows,
            invalidFile,
            message
          } = response;
          this.snackbar_class = "deep-purple";
          if (success && insertedRowCount > 0) {
            this.text_snackbar = `${insertedRowCount} row${
              insertedRowCount > 1 ? "s" : ""
            } imported successfully`;
            this.snackbar = true;
          } else if (failedRows) {
            this.text_snackbar = `Inserted count: ${response.insertedRowCount}, Failed count: ${response.failedRows.length}`;
            this.snackbar = true;
            this.exportCSVfile(response.titles, response.failedRows);
          } else if (!invalidFile && message) {
            this.text_snackbar = message;
            this.snackbar_class = "red";
            this.snackbar = true;
          }
          this.isSelectingIdposperassortments = false;
          this.getDataFromApi();
        } catch (error) {
          console.log(error);
          this.isSelectingIdposperassortments = false;
        }
      }
    },
    exportCSVfile(headers, rows) {
      let data = [];
      let header = {};
      let i = 0,
        j = 0;
      for (i = 0; i < headers.length; i++) {
        header[headers[i]] = headers[i];
      }
      console.log(header);
      for (i = 0; i < rows.length; i++) {
        let data_item = {};
        let current_key = "";
        for (j = 0; j < headers.length; j++) {
          current_key = headers[j];
          data_item[current_key] = rows[i][j];
        }
        data.push(data_item);
      }
      console.log(data);
      if (header) {
        data.unshift(header);
      }
      let jsonObject = JSON.stringify(data);
      let csv = this.convertToCSV(jsonObject);
      console.log(csv);
      let exportedFilenmae = "error_products.csv";
      var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae);
      } else {
        var link = document.createElement("a");
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", exportedFilenmae);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },
    convertToCSV(objArray) {
      var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
      var str = "";

      for (var i = 0; i < array.length; i++) {
        var line = "";
        for (var index in array[i]) {
          if (line != "") line += ",";

          line += array[i][index];
        }

        str += line + "\r\n";
      }

      return str;
    },
    async downloadExcel() {
      this.show();
      let query_params_string = this.getQueryParams();
      console.log(query_params_string);
      let fetch_url = "assortment/downloadexcel";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      const response = await ApiService.get(fetch_url, {
        responseType: "blob"
      });
      download(response, "assortments.xlsx");
      this.close();
    },
    async downloadExcelIdPOSperAssortments() {
      this.show();
      let query_params_string = this.getQueryParams();
      let fetch_url = "assortment/downloadexcel_idposperassortments";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      const response = await ApiService.get(fetch_url, {
        responseType: "blob"
      });
      download(response, "pos_per_assortments.xlsx");
      this.close();
    },
    async onSaveClick(item) {
      console.log(item);
      if (this.$refs.form.validate()) {
        let body = _.omit(item, ["brand"]);
        try {
          let status = null;
          if (body.id) {
            status = await ApiService.put(`pos/tags/${body.id}`, body);
          } else {
            status = await ApiService.post(`pos/tags`, body);
          }
          if (status?.success === false) {
            logError(`${status.message}`);
          } else {
            logInfo("éxito");
            this.showEdit = false;
            this.getDataFromApi();
          }
        } catch (error) {
          this.showEdit = false;
        }
      }
    },

    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getDataFromApi();
    },
    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
      this.getDataFromApi();
    },
    sortChange(params) {
      this.sorting = params;
      this.getDataFromApi();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.deleteItemId = -1;
      });
    },
    onItemRemove(item_id) {
      console.log(item_id);
      this.deleteItemId = item_id;
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      if (this.deleteItemId > 0) {
        this.closeDelete();
        ApiService.delete(`pos/tags/${this.deleteItemId}`).then(() => {
          logInfo("Surtido eliminada");
          this.getDataFromApi();
        });
      }
    },
    show() {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector("#data-table"),
        name: "flow" // gird, flow, wave, bounce,
      });
      this.loadingInstance.show();
    },
    close() {
      this.loadingInstance.close();
    },
    searchConfirm(closeFn) {
      closeFn();
      this.getDataFromApi();
    },
    searchCancel(closeFn, cancelFilterKey) {
      closeFn();
      this.filterCriteria[cancelFilterKey] = {};
      this.getDataFromApi();
    },
    getQueryParams() {
      let query_params_string = "";
      let query_params = [];
      query_params.push("page=" + this.pageIndex);
      query_params.push("itemsPerPage=" + this.pageSize);
      if (this.sorting !== null) {
        for (const [key, value] of Object.entries(this.sorting)) {
          if (value !== null && value !== "") {
            query_params.push("sortby=" + key);
            query_params.push(
              "sortdesc=" + (value === "desc" ? "true" : "false")
            );
          }
        }
      }

      let filterModel = [];
      for (const [key, value] of Object.entries(this.filterCriteria)) {
        if (value !== "" && value !== null) {
          if (
            key === "unit_price_without_vat" ||
            key === "returns_max" ||
            key === "priority_label"
          ) {
            if (
              (value["from"] !== "" &&
                value["from"] !== null &&
                value["from"] !== undefined) ||
              (value["to"] !== "" &&
                value["to"] !== null &&
                value["to"] !== undefined)
            ) {
              filterModel.push({
                columnField: key,
                filterValue: value
              });
            }
          } else if (Object.keys(value).length > 0) {
            filterModel.push({
              columnField: key,
              filterValue: value
            });
          }
        }
      }
      console.log(filterModel);
      let filter_columns = document.querySelectorAll(
        "#data-table .filterable-column"
      );
      for (let i = 0; i < filter_columns.length; i++) {
        filter_columns[i].classList.remove("filter_active");
      }
      if (filterModel.length > 0) {
        filterModel.map(filter_item => {
          for (let i = 0; i < filter_columns.length; i++) {
            if (filter_columns[i].classList.contains(filter_item.columnField)) {
              filter_columns[i].classList.add("filter_active");
            }
          }
          return filter_item;
        });
      }

      query_params_string += query_params.join("&");
      if (filterModel.length > 0) {
        query_params_string +=
          "&filterModel=" + encodeURIComponent(JSON.stringify(filterModel));
      }
      return query_params_string;
    },
    async getDataFromApi() {
      this.show();
      try {
        let query_params_string = this.getQueryParams();
        let fetch_url = "pos/tags";
        if (query_params_string !== "") {
          fetch_url += "?" + query_params_string;
        }
        const resp = await ApiService.get(fetch_url);
        this.assortments = resp.data;
        if (this.assortments.length > 0) {
          this.showEmpty = false;
        } else {
          this.showEmpty = true;
        }
        this.totalassortments = resp.total;
        this.labelColors = resp.labelColors;
        this.close();
      } catch (error) {
        this.close();
      }
    },
    async onChangeDates() {
      if (this.selected.startDate > this.selected.endDate) {
        this.selected.endDate = this.selected.startDate;
      }
    },
    init() {
      this.getDataFromApi();
    }
  },
  async mounted() {
    this.init();
  }
};
</script>

<style>
input.filter_date_picker {
  width: 100%;
  border: 1px solid #999;
  margin-top: 10px;
}
</style>
